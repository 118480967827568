import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179')];

export const server_loads = [];

export const dictionary = {
	"/": [2],
	"/10-lightweight-bootstrap-alternatives": [3],
	"/6-tips-for-managing-an-outsourced-team": [4],
	"/7-advantages-of-developing-user-personas-prior-to-app-development": [5],
	"/about-us": [6],
	"/angular-development-services": [7],
	"/angular-pros-and-cons": [8],
	"/application-modernization-services": [9],
	"/approach": [10],
	"/author/ivan": [11],
	"/author/oleg": [12],
	"/basics-of-developing-on-demand-service-app": [13],
	"/benefits-of-telehealth-for-patients-doctors": [14],
	"/best-logistics-software": [15],
	"/blog": [16],
	"/career": [17],
	"/career/full-stack-developer-java-angular": [18],
	"/career/full-stack-net-developer-angular": [19],
	"/career/middle-dotnet-developer": [20],
	"/career/python-developer": [21],
	"/career/ui-ux-designer": [22],
	"/construction-app-development-guide": [23],
	"/construction-industry-technology-trends": [24],
	"/construction-software-development": [25],
	"/contact-us": [26],
	"/cookie-policy": [27],
	"/crm-software-for-logistics": [28],
	"/custom-crm-development-services": [29],
	"/customer-experience-front-end-for-ai-solutions": [30],
	"/dedicated-development-team": [31],
	"/design-thinking-approach-in-software-development": [32],
	"/desktop-or-web-application-what-to-develop": [33],
	"/detailed-guide-on-building-enterprise-app": [34],
	"/developing-wellness-tech-solutions": [35],
	"/development-team-for-startup": [36],
	"/disrupting-recruitment-with-workforce-management-platforms": [37],
	"/dynamic-route-planning-and-optimization-explained": [38],
	"/education-software-development-services": [39],
	"/ehr-for-patient-care": [40],
	"/ehr-system-development-process": [41],
	"/electronic-medical-records-software-development": [42],
	"/energy-and-utilities-software-development-services": [43],
	"/enterprise-application-development-services": [44],
	"/erp-development-services": [45],
	"/erp-for-construction-industry-and-infrastructure": [46],
	"/finance-software-development": [47],
	"/fitness-wellness-development": [48],
	"/fleet-management-app-development": [49],
	"/fleet-management-software-development-company": [50],
	"/flutter-app-development-services": [51],
	"/freight-forwarding-software-solutions": [52],
	"/functional-non-functional-requirements": [53],
	"/future-of-proptech": [54],
	"/greentech-software-development": [55],
	"/healthcare-app-development-process": [56],
	"/healthcare-crm-software": [57],
	"/healthcare-logistics-overview": [58],
	"/healthcare-mobile-app-trends": [59],
	"/healthcare-mobile-apps-which-make-a-change": [60],
	"/healthcare-of-the-future": [61],
	"/healthcare-software-development": [62],
	"/healthcare-website-design": [63],
	"/hipaa-compliant-telehealth-platform": [64],
	"/how-to-build-a-mobile-banking-app": [65],
	"/how-to-build-a-product-from-scratch": [66],
	"/how-to-build-property-listing-portal": [67],
	"/how-to-create-a-messaging-app-like-whatsapp": [68],
	"/how-to-create-laboratory-information-management-system": [69],
	"/how-to-implement-gpt-into-your-app": [70],
	"/how-to-make-agile-and-outsourcing-work": [71],
	"/how-uber-freight-is-changing-the-freight-forwarding-market": [72],
	"/human-resource-management-software": [73],
	"/icons": [74],
	"/it-staff-augmentation-services": [75],
	"/java-development-services": [76],
	"/laboratory-information-management-systems": [77],
	"/logistics-software-development": [78],
	"/make-entity-framework-10x-faster-in-20-minutes": [79],
	"/make-gps-app-android-ios": [80],
	"/media-and-entertainment-software-development": [81],
	"/medical-billing-automation-for-healthcare-providers": [82],
	"/medication-reminder-app-development": [83],
	"/medtech-trends": [84],
	"/mobile-app-architecture": [85],
	"/mobile-development": [86],
	"/mobility-software-development": [87],
	"/mvp-vs-mcp-what-to-choose-for-your-project": [88],
	"/net-software-development-company": [89],
	"/net-vs-node-js": [90],
	"/not-found": [91],
	"/our-clients": [92],
	"/our-team": [93],
	"/our-works": [94],
	"/our-works/agriculture-software-solutions": [95],
	"/our-works/audit-management-system": [96],
	"/our-works/bridgestone-car-park-survey": [97],
	"/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics": [98],
	"/our-works/construction-supply-chain-automation": [99],
	"/our-works/custom-telemedicine-solution": [100],
	"/our-works/data-management-tool-for-tax-offices": [101],
	"/our-works/digital-platform-for-managing-gym-operation": [102],
	"/our-works/digital-solution-for-veteran-rehabilitation-center": [103],
	"/our-works/emergency-notifier": [104],
	"/our-works/ev-charging-software": [105],
	"/our-works/fee-foundation-of-economic-education": [106],
	"/our-works/formula-compounder": [107],
	"/our-works/freelance-marketplace-development": [108],
	"/our-works/health-metrics": [109],
	"/our-works/hostoo": [110],
	"/our-works/lab-web-portal": [111],
	"/our-works/land-tax-software": [112],
	"/our-works/medical-information-system-software": [113],
	"/our-works/migration-to-multi-tenant-saas-platform": [114],
	"/our-works/mind-your-service-mobile-gps-tracker": [116],
	"/our-works/mind-your-service": [115],
	"/our-works/national-lottery-app-modernization": [117],
	"/our-works/network-deposition-services": [118],
	"/our-works/optimizing-trucking-logistics": [119],
	"/our-works/outdoor-advertising-management-software": [120],
	"/our-works/qa-services-for-bank": [121],
	"/our-works/quarry-automation-platform": [122],
	"/our-works/road-trip-mobile-app": [123],
	"/our-works/saas-based-resident-experience-platform-for-senior-living": [124],
	"/our-works/sales-assistant-software": [125],
	"/our-works/sales-crm-for-education": [126],
	"/our-works/scan-tool": [127],
	"/our-works/scom-health-check-v2": [128],
	"/our-works/sloper-climbing-app": [129],
	"/our-works/std-status-app": [130],
	"/our-works/time-booking-tool": [131],
	"/our-works/travel-management-mobile-app": [132],
	"/our-works/web-portal-for-assistive-listening-systems": [133],
	"/our-works/wells-up": [134],
	"/personal-finance-app-development-guide": [135],
	"/pharmaceutical-document-management": [136],
	"/pharmacy-app-development-process": [137],
	"/platform-development": [138],
	"/privacy-policy": [139],
	"/product-development-services": [140],
	"/pros-and-cons-of-xamarin-app-development": [141],
	"/quality-assurance": [142],
	"/reactjs-development-services": [143],
	"/real-estate-communication-with-advanced-tech": [144],
	"/remote-patient-monitoring-development-guide": [145],
	"/software-development-for-startups": [146],
	"/software-development-methodologies-pros-cons": [147],
	"/software-outsourcing-ukraine-hire-software-developers": [148],
	"/supply-chain-improvement-guide": [149],
	"/supply-chain-management-software-features-requirements": [150],
	"/support-and-maintenance": [151],
	"/system-development-life-cycle": [152],
	"/team-extension-services": [153],
	"/technology-for-elderly-care-services": [155],
	"/technology-modernization": [156],
	"/technology": [154],
	"/telehealth-in-healthcare-rural-areas": [157],
	"/telemedicine-software-development": [158],
	"/the-pillars-of-a-winning-telehealth-strategy": [159],
	"/the-role-of-a-cto-in-a-startup-mission-responsibilities-and-skills": [160],
	"/time-and-materials-vs-fixed-price-vs-scope-based-model-choosing-the-right-pricing-model-for-your-project": [161],
	"/top-6-logistics-startups-to-watch-out": [162],
	"/top-9-wireframing-and-prototyping-tools": [163],
	"/trucking-app-development-guide": [164],
	"/ui-ux-design-services": [165],
	"/vue-js-development-services": [166],
	"/warehouse-management-system-wms": [167],
	"/warehouse-optimization-tips": [168],
	"/web-app-architecture-types-components-trends": [169],
	"/web-development": [170],
	"/what-can-you-do-with-java": [171],
	"/what-is-a-lims": [172],
	"/what-is-last-mile-delivery-technology": [173],
	"/what-is-samd-all-about-software-as-a-medical-device": [174],
	"/what-is-warehouse-management-system": [175],
	"/what-you-need-to-know-about-building-mvp": [176],
	"/why-a-fixed-price-contract-is-a-bad-idea-for-your-project": [177],
	"/why-every-logistics-business-must-go-mobile": [178],
	"/xamarin-app-development-services": [179]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};